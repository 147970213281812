/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.brand-logo img {
  max-width: 100px !important;
}


button.btn .btn-outline-primary .active{
  background-color: #7367f05e !important;
  color: white !important;
}

.logoAlfa{
 margin-bottom: 30px;
}


.buttonSpeed{
  margin: auto;
}

.operators{
  margin-bottom: 20px;
}

.popupTest{
  font-size: 12px;
  color: #1e1e1e;
}

// ---- MAP

// ------- search
#geocoder {
  position: absolute;
  width: 300px;
  border: 1px solid #7367f0;
  background-color: #7367f0;
  border-radius: 10px;
  padding: 0.6rem;
}
#geocoder {
  .mapboxgl-ctrl-geocoder {
    //background-image: url(../images/custom/icSearchPOI-light.svg);
    background-size: 12px auto;
    background-position: 4px 4px;
    background-repeat: no-repeat;
    padding-left: 30px;
  }
}
#geocoder {
  .mapboxgl-ctrl-geocoder {
    input.mapboxgl-ctrl-geocoder--input {
      border-width: 0;
      box-shadow: none;
      background-color: transparent;
      width: 100%;
      color: #fff;
      outline: none;
    }
  }
}
/* Edge */
#geocoder {
  .mapboxgl-ctrl-geocoder  {
    input.mapboxgl-ctrl-geocoder--input::-webkit-input-placeholder {
      color: #fff;
      opacity: 0.6;
    }
  }
}
/* Internet Explorer 10-11 */
#geocoder {
  .mapboxgl-ctrl-geocoder {
    input.mapboxgl-ctrl-geocoder--input:-ms-input-placeholder {
      color: #fff;
      opacity: 0.6;
    }
  }
}
#geocoder  {
  .mapboxgl-ctrl-geocoder {
    input.mapboxgl-ctrl-geocoder--input::placeholder {
      color: #fff;
      opacity: 0.6;
    }
  }
}

// select suggest
.suggestions-wrapper {
  .suggestions li:first-child {
    margin-top: 0.5rem;
  }
}
.suggestions-wrapper {
  .suggestions {
    li {
      color: #fff;
      opacity: 0.6;
      font-size: 0.8rem;
    }
  }
}

.suggestions-wrapper {
  .suggestions {
    li.active {
      color: #fff;
      opacity: 1;
    }
  }
}
.suggestions-wrapper {
  .suggestions {
    li:hover {
      color: #fff;
      opacity: 0.6;
      text-decoration: none;
    }
  }
}
:focus-visible {
  outline:none !important;
}

// text Powered by MAPBOX
.mapboxgl-ctrl-geocoder--powered-by {
  display: none;
}

// icon Lente Search
#geocoder {
  .mapboxgl-ctrl-geocoder {
    svg {
      display: none;
    }
  }
}
// icon X close search
#geocoder {
  .mapboxgl-ctrl-geocoder--pin-right {
    display: none;
  }
}

#geocoder {
  z-index: 1;
  margin: 20px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}
.marker {
  background-image: url('../images/location.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}



// bottom logo MapBOX
.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
  display: none !important;
}
.map-container {
  height: 800px;
}

@media only screen and (max-height: 960px) {
  .map-container {
    max-height: 680px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 760px;
  }

}
@media only screen and (max-height: 940px) {
  .map-container {
    max-height: 660px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 740px;
  }

}
@media only screen and (max-height: 920px) {
  .map-container {
    max-height: 640px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 720px;
  }

}
@media only screen and (max-height: 900px) {
  .map-container {
    max-height: 620px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 700px;
  }

}
@media only screen and (max-height: 880px) {
  .map-container {
    max-height: 600px;
    min-height: 500px !important;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 680px;
  }

}
@media only screen and (max-height: 860px) {
  .map-container {
    max-height: 580px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 660px;
  }

}
@media only screen and (max-height: 840px) {
  .map-container {
    max-height: 540px;
    min-height: 400px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 620px;
  }

}
.buttons-years{
  Button{
    margin: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .map-container {
    max-height: 520px;
    min-height: 400px;
  }
  .select-sidebar, .airquality-sidebar {
    max-height: 600px;
  }
  .numTests{
  margin-bottom: 10px;
  margin-top: 20px;
}
  .buttons-years{
  Button{
    margin: 7px;
  }
}
}
.map-container .legend {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.8;
}
